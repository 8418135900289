<template>
  <div class="flex justify-center items-center">
    <div
      class="container flex flex-col flex-wrap items-center px-6 py-4 mx-auto md:flex-row bg-white bg-opacity-90 rounded-lg shadow-lg fixed top-8 left-0 right-0 z-50">
      <samii-logo />
      <nav class="cursor-pointer flex flex-wrap items-center justify-center space-x-12 text-md md:ml-auto">
        <a class="text-gray-500 no-underline mb-2" style="user-select: none;" @click="goto('introduction')">
          Inicio
        </a>
        <a class="cursor-pointer text-gray-500 no-underline mb-2" style="user-select: none;" @click="goto('features')">
          Características
        </a>
        <a class="cursor-pointer text-gray-500 no-underline mb-2" style="user-select: none;" @click="goto('pricing')">
          Planes
        </a>
        <a class="cursor-pointer text-gray-500 no-underline mb-2" style="user-select: none;" @click="goto('contact')">
          Contacto
        </a>
        <sa-button @click="$router.push({ path: '/login' })">
          Iniciar sesión
        </sa-button>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TheHeader',
  components: {
    SamiiLogo: () => import('@/components/atoms/SamiiLogo.vue'),
  },
  methods: {
    scrollToElement(id) {
      const element = document.getElementById(id);
      if (element) {
        const top = element.offsetTop;
        window.scrollTo({ top: top - 128, behavior: 'smooth' });
      }
    },
    goto(id) {
      if (this.$route.path === '/' && ['introduction', 'features', 'pricing'].includes(id)) {
        this.scrollToElement(id);
      } else if (this.$route.path === '/help-center' && id === 'contact') {
        this.scrollToElement(id);
      } else {
        const targetRoute =
          id === 'contact' ? { name: 'help-center.index' } : { name: 'landing.index' };

        this.$router.push(targetRoute).then(() => {
          this.$nextTick(() => {
            this.scrollToElement(id);
          });
        });
      }
    },
  }
}
</script>
