<template>
  <div class="min-h-screen bg-right bg-cover bg-gray-100">
    <div class="flex flex-col min-h-screen">
      <the-header />
      <main class="container mx-auto ml-auto font-headline pt-32 flex-grow">
        <slot />
      </main>
      <the-footer />
    </div>
  </div>
</template>
<script>
import TheHeader from '@/components/organisms/TheHeader.vue';
import TheFooter from '@/components/organisms/TheFooter.vue';

export default {
  name: 'PublicLayout',
  components: {
    TheHeader,
    TheFooter
  },
}
</script>
